.page-footer {
  padding-top: 20px;
  border: none;
  .footer-logo {
    background-image: url("common/img/logo_salesoar_4.png");
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 140px;
    height: 33px;
  }

  background-color: var(--md-source-core-colors-automated-green1);
  p,
  a {
    color: var(--md-source-core-colors-white);
  }
}
