#modal-select-country {
  max-width: 576px;
}

.select-country-item {
    height: 60px;
    .flag-icon-select {
      max-height: 40px;
      max-width: 40px;
    }
  }

  @media #{$small-and-down} {
    .select-country-item {
      height: 60px;
    }
  }
