nav {
  --navbar-height: 64px;
  --navbar-height-mobile: 128px;

  background-color: var(--md-source-core-colors-green-sales);

  .input-field {
    .prefix {
      color: var(--md-source-core-colors-green-sales);
      height: 24px;
      line-height: 24px;
      top: 20px;
    }
  }

  .brand-logo {
    background-image: url("common/img/logo_nav.png");
    height: 61px;
    width: 181px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
    .brand-logo {
      background-image: url("common/img/logo_nav@2x.png");
      background-size: 165px;
    }
  }

  @media #{$small-and-down} {
    .brand-logo {
      background-size: 130px;
      height: 57px;
      width: 100%;
    }
    .input-field {
      input[type="text"] {
        height: 40px;
      }
      .prefix {
        top: 52px;
      }
    }
  }

  .flag-icon {
    max-width: 40px;
    max-height: 40px;
  }

  .country-ico {
    width: 40px;
    height: 40px;
    line-height: 40px;

    a {
      padding: 0;
      width: 40px;
      height: 40px;
      line-height: 40px;
      color: var(--md-source-core-colors-white);
    }
  }

  @media #{$small-and-down} {
    .flag-icon {
      max-width: 32px;
      max-height: 32px;
    }
    .country-ico {
      width: 32px;
      height: 32px;
      line-height: 32px;
    }
  }
}
