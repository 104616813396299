$font-size: 26px;
$line-height: 1.4;
$lines-to-show: 3;

span {
  &.badge {
    &.new {
      &:after {
        content: "";
      }
    }
  }
}

.search-result {
  padding-top: 20px;
  padding-bottom: 20px;

  .prod-list {
    padding-top: 20px;
    .prod-empty-list {
      border: 1px solid var(--md-source-core-colors-automated-green3);
      padding: 20px;
      text-align: center;
      font-size: 18px;
      color: var(--md-source-core-colors-green-sales);
      background-color: var(--md-sys-color-surface-light);
    }
    .prod-item {
      border: 1px solid var(--md-source-core-colors-automated-green3);
      background-color: var(--md-source-core-colors-white0);
      overflow: hidden;
      border-radius: 0;
      transition: none;
      position: relative;
      .item-image {
        .image-container {
          text-align: center;
          height: 180px;
          @media #{$medium-only} {
            height: 150px;
          }
          @media #{$small-and-down} {
            height: 120px;
          }
          img {
            display: inline-block; /* Instead of display: block; */
            margin: 0 auto;
            vertical-align: middle;
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
          }
        }
      }
      .item-content {
        padding: 16px;
        .item-brand {
          height: 44px;
          font-size: 16px;
          line-height: 20px;
          font-weight: bold;
          padding-bottom: 10px;
          color: var(--md-source-core-colors-green-sales);
        }
        .item-title {
          height: 56px;
          font-size: 14px;
          line-height: 18px;
          overflow: hidden;
          word-break: break-word;
          color: var(--md-source-core-colors-green-sales);
        }
      }
      .item-price {
        font-size: 16px;
        color: var(--md-source-core-colors-automated-green);
        padding-top: 12px;
        height: 56px;
        margin-bottom: 4px;
        .price {
          font-size: 12px;
          color: color("grey", "darken-2");
          text-decoration: line-through;
        }
      }
      .item-shop-name {
        .shop-name {
          font-size: 14px !important;
          color: color("grey", "darken-2") !important;
          text-overflow: ellipsis;
          overflow: hidden;
          height: 21px;
        }
      }
      .item-shipping {
        margin-top: 6px;
        .shipping-price {
          font-size: 14px;
          color: color("grey", "darken-2");
          .material-icons {
            margin-right: 8px;
            font-size: 20px;
          }
        }
      }
      .item-cta {
        text-align: center;
        padding-bottom: 8px;
        .cta {
          width: 90%;
          &.btn.filled {
            background-color: var(--md-source-core-colors-green-soar);
            color: var(--md-source-core-colors-smart-green);
            display: inline-grid;
          }
        }
      }
      .item-badge {
        line-height: 1.6em;
        position: absolute;
        z-index: 10;
        top: 0.8rem;
        right: -2.4rem;
        display: block;
        width: 8rem;
        height: 1.6em;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        background: var(--md-source-core-colors-automated-green);

        a {
          position: relative;
          display: block;
          width: 100%;
          height: 100%;
          text-align: center;
          text-decoration: none;
          color: var(--md-source-core-colors-white1);
        }
        a:hover {
          text-decoration: none;
        }
        a:after,
        a:before {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 3px;
          content: "";
        }
        a:after {
          top: auto;
          bottom: 0;
          border-bottom-width: 0;
        }
      }
    }
  }

  @media #{$small-and-down} {
    #filters {
      display: none;
    }
  }
  .show-filter-btn {
    position: absolute;
    top: 11px;
    right: 24px;
  }

  .bc-category {
    padding: 8px 16px;
    border: 1px solid var(--md-source-core-colors-automated-green3);
    background-color: var(--md-source-core-colors-egg);
    color: var(--md-source-core-colors-green-sales);
    .breadcrumb {
      font-size: 16px;
      color: var(--md-source-core-colors-green-sales);
      &:last-child {
        color: var(--md-source-core-colors-green-sales);
      }
      &:before {
        color: var(--md-source-core-colors-green-sales);
        font-size: 18px;
      }
    }

    @media #{$medium-and-down} {
      .breadcrumb {
        font-size: 14px;
      }
    }

    @media #{$small-and-down} {
      .breadcrumb {
        font-size: 12px;
      }
    }
    a.breadcrumb:hover {
      text-decoration: underline;
    }
  }

  .bc-prod-list {
    padding: 4px 16px;
    border: 1px solid var(--md-source-core-colors-automated-green3);
    background-color: var(--md-source-core-colors-egg);
    color: var(--md-source-core-colors-green-sales);

    .row {
      margin-bottom: 0;
    }

    .bc-prod-list-row {
      line-height: 30px;
      .chip,
      .btn-small {
        height: 28px;
        line-height: 28px;
      }
      .chip {
        background-color: var(--md-source-core-colors-green-soar3);
        a {
          color: var(--md-source-core-colors-green-sales);
          font-size: 14px;
          .material-icons {
            font-size: 18px;
          }
        }
      }
    }
    .bc-prod-list-col {
      margin: 0 16px 0 0;
    }
    .dropdown-trigger {
      background-color: var(--md-source-core-colors-green-soar3);
      color: var(--md-source-core-colors-green-sales);
    }

    .dropdown-content {
      color: var(--md-source-core-colors-green-sales);
      li {
        a {
          color: var(--md-source-core-colors-green-sales);
          background-color: var(--md-source-core-colors-white);
          font-size: 14px;
        }
      }
      a:hover {
        text-decoration: underline;
      }
    }
  }

  .menu-filters {
    padding: 16px;
    border: 1px solid var(--md-source-core-colors-automated-green3);
    background-color: var(--md-source-core-colors-egg);
    color: var(--md-source-core-colors-green-sales);
    .categories-menu {
      border: none;
      box-shadow: none;
      height: 400px;
      overflow-y: auto;
      position: relative;

      li {
        a {
          color: var(--md-source-core-colors-green-sales);
        }
        a:hover {
          text-decoration: underline;
        }
        &.collection-item {
          padding-left: 0;
          padding-right: 0;
          a {
            &.active {
              color: var(--md-source-core-colors-automated-green);
            }
          }
          .collection-sub-item {
            @extend .collection-item;
            padding-left: 10px;
            padding-right: 0;
            li {
              padding-top: 4px;
              padding-bottom: 4px;
            }
          }
        }
      }
    }
    .brand-menu,
    .merchant-menu,
    .discount-menu {
      overflow-y: auto;
      position: relative;
      border: none;
      li {
        label {
          color: var(--md-source-core-colors-green-sales);
        }
      }
    }
    .range-field {
      #price-slider {
        width: 90%;
        margin: auto;
      }
      #price-slider-value {
        width: 90%;
        margin: auto;
      }
      .noUi-connect {
        background: var(--md-source-core-colors-automated-green);
      }
      .noUi-horizontal {
        height: 8px;
      }
      .noUi-handle {
        background: var(--md-source-core-colors-automated-green);
        width: 20px;
        height: 20px;
        right: -10px;
        top: -7px;
        box-shadow: none;
        border-radius: 12px;
        &::before,
        &::after {
          content: unset;
        }
      }
      .noUi-horizontal .noUi-handle,
      .noUi-vertical .noUi-handle {
        background: var(--md-source-core-colors-automated-green);
      }
      .noUi-target.noUi-horizontal .noUi-tooltip {
        background-color: var(--md-source-core-colors-automated-green);
      }
    }
  }
  .brand-menu,
  .merchant-menu {
    height: 250px;
  }
  .discount-menu {
    height: 180px;
  }
}
