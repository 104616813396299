#tos {
    h1 {
        font-size: 24px;
        font-weight: bold;
    }

    h2 {
        font-size: 18px;
        font-weight: bold;
    }
}