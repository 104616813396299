:root {
  --md-source: #268a07;
  --md-source-core-colors-green-sales: #022e13;
  --md-source-core-colors-smart-green: #04572d;
  --md-source-core-colors-automated-green: #268a07;
  --md-source-core-colors-green-soar: #91e60c;
  --md-source-core-colors-automated-green1: #6aad53;
  --md-source-core-colors-automated-green2: #96c485;
  --md-source-core-colors-automated-green3: #c3dbb8;
  --md-source-core-colors-green-soar1: #b1ea57;
  --md-source-core-colors-green-soar2: #c6ed87;
  --md-source-core-colors-green-soar3: #dbefb9;
  --md-source-core-colors-black: #011408;
  --md-source-core-colors-egg: #e1e9d8;
  --md-source-core-colors-white: #f0f2eb;
  --md-source-core-colors-white1: #f1faed;
  --md-source-core-colors-white0: #ffffff;

  /* primary */
  --md-ref-palette-primary0: #000000;
  --md-ref-palette-primary5: #021500;
  --md-ref-palette-primary10: #032100;
  --md-ref-palette-primary15: #062d00;
  --md-ref-palette-primary20: #093900;
  --md-ref-palette-primary25: #0d4600;
  --md-ref-palette-primary30: #115300;
  --md-ref-palette-primary35: #156000;
  --md-ref-palette-primary40: #196e00;
  --md-ref-palette-primary50: #258906;
  --md-ref-palette-primary60: #43a527;
  --md-ref-palette-primary70: #5ec141;
  --md-ref-palette-primary80: #79dd5a;
  --md-ref-palette-primary90: #bef0a7;
  --md-ref-palette-primary95: #ccffb5;
  --md-ref-palette-primary98: #edffe0;
  --md-ref-palette-primary99: #f7ffed;
  --md-ref-palette-primary100: #ffffff;
  /* secondary */
  --md-ref-palette-secondary0: #000000;
  --md-ref-palette-secondary5: #001506;
  --md-ref-palette-secondary10: #00210b;
  --md-ref-palette-secondary15: #012d12;
  --md-ref-palette-secondary20: #0d381c;
  --md-ref-palette-secondary25: #1a4326;
  --md-ref-palette-secondary30: #264f31;
  --md-ref-palette-secondary35: #325b3b;
  --md-ref-palette-secondary40: #3e6747;
  --md-ref-palette-secondary50: #56815e;
  --md-ref-palette-secondary60: #6f9b76;
  --md-ref-palette-secondary70: #89b690;
  --md-ref-palette-secondary80: #a4d2aa;
  --md-ref-palette-secondary90: #bfeec4;
  --md-ref-palette-secondary95: #cdfdd2;
  --md-ref-palette-secondary98: #eaffe9;
  --md-ref-palette-secondary99: #f5fff2;
  --md-ref-palette-secondary100: #ffffff;
  /* tertiary */
  --md-ref-palette-tertiary0: #000000;
  --md-ref-palette-tertiary5: #081400;
  --md-ref-palette-tertiary10: #102000;
  --md-ref-palette-tertiary15: #172b00;
  --md-ref-palette-tertiary20: #1f3700;
  --md-ref-palette-tertiary25: #274300;
  --md-ref-palette-tertiary30: #2f4f00;
  --md-ref-palette-tertiary35: #375c00;
  --md-ref-palette-tertiary40: #406900;
  --md-ref-palette-tertiary50: #518500;
  --md-ref-palette-tertiary60: #63a100;
  --md-ref-palette-tertiary70: #76be00;
  --md-ref-palette-tertiary80: #8adc00;
  --md-ref-palette-tertiary90: #caee9c;
  --md-ref-palette-tertiary95: #d8fda9;
  --md-ref-palette-tertiary98: #f0ffd7;
  --md-ref-palette-tertiary99: #f9ffe9;
  --md-ref-palette-tertiary100: #ffffff;
  /* neutral */
  --md-ref-palette-neutral0: #000000;
  --md-ref-palette-neutral5: #111110;
  --md-ref-palette-neutral10: #1c1c1b;
  --md-ref-palette-neutral15: #262625;
  --md-ref-palette-neutral20: #31302f;
  --md-ref-palette-neutral25: #3c3b3a;
  --md-ref-palette-neutral30: #474745;
  --md-ref-palette-neutral35: #535251;
  --md-ref-palette-neutral40: #5f5e5d;
  --md-ref-palette-neutral50: #787775;
  --md-ref-palette-neutral60: #92908f;
  --md-ref-palette-neutral70: #adaba9;
  --md-ref-palette-neutral80: #c9c6c4;
  --md-ref-palette-neutral90: #e5e2e0;
  --md-ref-palette-neutral95: #f3f0ee;
  --md-ref-palette-neutral98: #fcf9f7;
  --md-ref-palette-neutral99: #f7ffed;
  --md-ref-palette-neutral100: #ffffff;
  /* neutral-variant */
  --md-ref-palette-neutral-variant0: #000000;
  --md-ref-palette-neutral-variant5: #0d120b;
  --md-ref-palette-neutral-variant10: #181d15;
  --md-ref-palette-neutral-variant15: #22271f;
  --md-ref-palette-neutral-variant20: #2d3229;
  --md-ref-palette-neutral-variant25: #383d34;
  --md-ref-palette-neutral-variant30: #43483f;
  --md-ref-palette-neutral-variant35: #4f544a;
  --md-ref-palette-neutral-variant40: #5a6056;
  --md-ref-palette-neutral-variant50: #73796e;
  --md-ref-palette-neutral-variant60: #8d9387;
  --md-ref-palette-neutral-variant70: #a8ada1;
  --md-ref-palette-neutral-variant80: #c3c8bc;
  --md-ref-palette-neutral-variant90: #dfe4d7;
  --md-ref-palette-neutral-variant95: #edf3e5;
  --md-ref-palette-neutral-variant98: #f6fbee;
  --md-ref-palette-neutral-variant99: #f9fef0;
  --md-ref-palette-neutral-variant100: #ffffff;
  // /* error */
  --md-ref-palette-error0: #000000;
  --md-ref-palette-error10: #410002;
  --md-ref-palette-error20: #690005;
  --md-ref-palette-error25: #7e0007;
  --md-ref-palette-error30: #93000a;
  --md-ref-palette-error35: #a80710;
  --md-ref-palette-error40: #ba1a1a;
  --md-ref-palette-error50: #de3730;
  --md-ref-palette-error60: #ff5449;
  --md-ref-palette-error70: #ff897d;
  --md-ref-palette-error80: #ffb4ab;
  --md-ref-palette-error90: #ffdad6;
  --md-ref-palette-error95: #ffedea;
  --md-ref-palette-error98: #fff8f7;
  --md-ref-palette-error99: #fffbff;
  --md-ref-palette-error100: #ffffff;
  /* light */
  --md-sys-color-primary-light: #145d00;
  --md-sys-color-surface-tint-light: #196e00;
  --md-sys-color-on-primary-light: #ffffff;
  --md-sys-color-primary-container-light: #228702;
  --md-sys-color-on-primary-container-light: #ffffff;
  --md-sys-color-secondary-light: #001b08;
  --md-sys-color-on-secondary-light: #ffffff;
  --md-sys-color-secondary-container-light: #133d20;
  --md-sys-color-on-secondary-container-light: #a3d0a8;
  --md-sys-color-tertiary-light: #406900;
  --md-sys-color-on-tertiary-light: #ffffff;
  --md-sys-color-tertiary-container-light: #97ed1b;
  --md-sys-color-on-tertiary-container-light: #2a4800;
  --md-sys-color-error-light: #ba1a1a;
  --md-sys-color-on-error-light: #ffffff;
  --md-sys-color-error-container-light: #ffdad6;
  --md-sys-color-on-error-container-light: #410002;
  --md-sys-color-background-light: var(--md-source-core-colors-white);
  --md-sys-color-on-background-light: #171d14;
  --md-sys-color-surface-light: #fcf9f6;
  --md-sys-color-on-surface-light: #1c1c1a;
  --md-sys-color-surface-variant-light: #dbe6d0;
  --md-sys-color-on-surface-variant-light: #3f4a3a;
  --md-sys-color-outline-light: #6f7a68;
  --md-sys-color-outline-variant-light: #bfcab5;
  --md-sys-color-shadow-light: #000000;
  --md-sys-color-scrim-light: #000000;
  --md-sys-color-inverse-surface-light: #31302f;
  --md-sys-color-inverse-on-surface-light: #f3f0ee;
  --md-sys-color-inverse-primary-light: #79dd5a;
  --md-sys-color-primary-fixed-light: #94fb73;
  --md-sys-color-on-primary-fixed-light: #032100;
  --md-sys-color-primary-fixed-dim-light: #79dd5a;
  --md-sys-color-on-primary-fixed-variant-light: #115300;
  --md-sys-color-secondary-fixed-light: #bfeec4;
  --md-sys-color-on-secondary-fixed-light: #00210b;
  --md-sys-color-secondary-fixed-dim-light: #a4d2aa;
  --md-sys-color-on-secondary-fixed-variant-light: #264f31;
  --md-sys-color-tertiary-fixed-light: #a3fa2d;
  --md-sys-color-on-tertiary-fixed-light: #102000;
  --md-sys-color-tertiary-fixed-dim-light: #8adc00;
  --md-sys-color-on-tertiary-fixed-variant-light: #2f4f00;
  --md-sys-color-surface-dim-light: #dcd9d7;
  --md-sys-color-surface-bright-light: #fcf9f6;
  --md-sys-color-surface-container-lowest-light: #ffffff;
  --md-sys-color-surface-container-low-light: #f6f3f1;
  --md-sys-color-surface-container-light: #f0edeb;
  --md-sys-color-surface-container-high-light: #eae8e5;
  --md-sys-color-surface-container-highest-light: #e5e2e0;
  /* dark */
  --md-sys-color-primary-dark: #79dd5a;
  --md-sys-color-surface-tint-dark: #79dd5a;
  --md-sys-color-on-primary-dark: #093900;
  --md-sys-color-primary-container-dark: #1e7f00;
  --md-sys-color-on-primary-container-dark: #ffffff;
  --md-sys-color-secondary-dark: #a4d2aa;
  --md-sys-color-on-secondary-dark: #0d381c;
  --md-sys-color-secondary-container-dark: #00250d;
  --md-sys-color-on-secondary-container-dark: #88b48e;
  --md-sys-color-tertiary-dark: #e0ffb7;
  --md-sys-color-on-tertiary-dark: #1f3700;
  --md-sys-color-tertiary-container-dark: #8ce000;
  --md-sys-color-on-tertiary-container-dark: #254000;
  --md-sys-color-error-dark: #ffb4ab;
  --md-sys-color-on-error-dark: #690005;
  --md-sys-color-error-container-dark: #93000a;
  --md-sys-color-on-error-container-dark: #ffdad6;
  --md-sys-color-background-dark: #0f150c;
  --md-sys-color-on-background-dark: #dee5d6;
  --md-sys-color-surface-dark: #131312;
  --md-sys-color-on-surface-dark: #e5e2e0;
  --md-sys-color-surface-variant-dark: #3f4a3a;
  --md-sys-color-on-surface-variant-dark: #bfcab5;
  --md-sys-color-outline-dark: #899481;
  --md-sys-color-outline-variant-dark: #3f4a3a;
  --md-sys-color-shadow-dark: #000000;
  --md-sys-color-scrim-dark: #000000;
  --md-sys-color-inverse-surface-dark: #e5e2e0;
  --md-sys-color-inverse-on-surface-dark: #31302f;
  --md-sys-color-inverse-primary-dark: #196e00;
  --md-sys-color-primary-fixed-dark: #94fb73;
  --md-sys-color-on-primary-fixed-dark: #032100;
  --md-sys-color-primary-fixed-dim-dark: #79dd5a;
  --md-sys-color-on-primary-fixed-variant-dark: #115300;
  --md-sys-color-secondary-fixed-dark: #bfeec4;
  --md-sys-color-on-secondary-fixed-dark: #00210b;
  --md-sys-color-secondary-fixed-dim-dark: #a4d2aa;
  --md-sys-color-on-secondary-fixed-variant-dark: #264f31;
  --md-sys-color-tertiary-fixed-dark: #a3fa2d;
  --md-sys-color-on-tertiary-fixed-dark: #102000;
  --md-sys-color-tertiary-fixed-dim-dark: #8adc00;
  --md-sys-color-on-tertiary-fixed-variant-dark: #2f4f00;
  --md-sys-color-surface-dim-dark: #131312;
  --md-sys-color-surface-bright-dark: #393938;
  --md-sys-color-surface-container-lowest-dark: #0e0e0d;
  --md-sys-color-surface-container-low-dark: #1c1c1a;
  --md-sys-color-surface-container-dark: #20201e;
  --md-sys-color-surface-container-high-dark: #2a2a29;
  --md-sys-color-surface-container-highest-dark: #353533;

  // --md-sys-color-scrim-dark: #000000;
  // /* display - large */
  --md-sys-typescale-display-large-font-family-name: Epilogue;
  // --md-sys-typescale-display-large-font-family-style: Regular;
  // --md-sys-typescale-display-large-font-weight: 400px;
  // --md-sys-typescale-display-large-font-size: 57px;
  // --md-sys-typescale-display-large-line-height: 64px;
  // --md-sys-typescale-display-large-letter-spacing: -0.25px;
  // /* display - medium */
  --md-sys-typescale-display-medium-font-family-name: Epilogue;
  // --md-sys-typescale-display-medium-font-family-style: Regular;
  // --md-sys-typescale-display-medium-font-weight: 400px;
  // --md-sys-typescale-display-medium-font-size: 45px;
  // --md-sys-typescale-display-medium-line-height: 52px;
  // --md-sys-typescale-display-medium-letter-spacing: 0px;
  // /* display - small */
  --md-sys-typescale-display-small-font-family-name: Epilogue;
  // --md-sys-typescale-display-small-font-family-style: Regular;
  // --md-sys-typescale-display-small-font-weight: 400px;
  // --md-sys-typescale-display-small-font-size: 36px;
  // --md-sys-typescale-display-small-line-height: 44px;
  // --md-sys-typescale-display-small-letter-spacing: 0px;
  // /* headline - large */
  --md-sys-typescale-headline-large-font-family-name: Epilogue;
  // --md-sys-typescale-headline-large-font-family-style: Regular;
  // --md-sys-typescale-headline-large-font-weight: 400px;
  // --md-sys-typescale-headline-large-font-size: 32px;
  // --md-sys-typescale-headline-large-line-height: 40px;
  // --md-sys-typescale-headline-large-letter-spacing: 0px;
  // /* headline - medium */
  --md-sys-typescale-headline-medium-font-family-name: Epilogue;
  // --md-sys-typescale-headline-medium-font-family-style: Regular;
  // --md-sys-typescale-headline-medium-font-weight: 400px;
  // --md-sys-typescale-headline-medium-font-size: 28px;
  // --md-sys-typescale-headline-medium-line-height: 36px;
  // --md-sys-typescale-headline-medium-letter-spacing: 0px;
  // /* headline - small */
  --md-sys-typescale-headline-small-font-family-name: Epilogue;
  // --md-sys-typescale-headline-small-font-family-style: Regular;
  // --md-sys-typescale-headline-small-font-weight: 400px;
  // --md-sys-typescale-headline-small-font-size: 24px;
  // --md-sys-typescale-headline-small-line-height: 32px;
  // --md-sys-typescale-headline-small-letter-spacing: 0px;
  // /* body - large */
  --md-sys-typescale-body-large-font-family-name: Epilogue;
  // --md-sys-typescale-body-large-font-family-style: Regular;
  // --md-sys-typescale-body-large-font-weight: 400px;
  // --md-sys-typescale-body-large-font-size: 16px;
  // --md-sys-typescale-body-large-line-height: 24px;
  // --md-sys-typescale-body-large-letter-spacing: 0.50px;
  // /* body - medium */
  --md-sys-typescale-body-medium-font-family-name: Epilogue;
  // --md-sys-typescale-body-medium-font-family-style: Regular;
  // --md-sys-typescale-body-medium-font-weight: 400px;
  // --md-sys-typescale-body-medium-font-size: 14px;
  // --md-sys-typescale-body-medium-line-height: 20px;
  // --md-sys-typescale-body-medium-letter-spacing: 0.25px;
  // /* body - small */
  --md-sys-typescale-body-small-font-family-name: Epilogue;
  // --md-sys-typescale-body-small-font-family-style: Regular;
  // --md-sys-typescale-body-small-font-weight: 400px;
  // --md-sys-typescale-body-small-font-size: 12px;
  // --md-sys-typescale-body-small-line-height: 16px;
  // --md-sys-typescale-body-small-letter-spacing: 0.40px;
  // /* label - large */
  --md-sys-typescale-label-large-font-family-name: Epilogue;
  // --md-sys-typescale-label-large-font-family-style: Medium;
  // --md-sys-typescale-label-large-font-weight: 500px;
  // --md-sys-typescale-label-large-font-size: 14px;
  // --md-sys-typescale-label-large-line-height: 20px;
  // --md-sys-typescale-label-large-letter-spacing: 0.10px;
  // /* label - medium */
  --md-sys-typescale-label-medium-font-family-name: Epilogue;
  // --md-sys-typescale-label-medium-font-family-style: Medium;
  // --md-sys-typescale-label-medium-font-weight: 500px;
  // --md-sys-typescale-label-medium-font-size: 12px;
  // --md-sys-typescale-label-medium-line-height: 16px;
  // --md-sys-typescale-label-medium-letter-spacing: 0.50px;
  // /* label - small */
  --md-sys-typescale-label-small-font-family-name: Epilogue;
  // --md-sys-typescale-label-small-font-family-style: Medium;
  // --md-sys-typescale-label-small-font-weight: 500px;
  // --md-sys-typescale-label-small-font-size: 11px;
  // --md-sys-typescale-label-small-line-height: 16px;
  // --md-sys-typescale-label-small-letter-spacing: 0.50px;
  // /* title - large */
  --md-sys-typescale-title-large-font-family-name: Epilogue;
  // --md-sys-typescale-title-large-font-family-style: Regular;
  // --md-sys-typescale-title-large-font-weight: 400px;
  // --md-sys-typescale-title-large-font-size: 22px;
  // --md-sys-typescale-title-large-line-height: 28px;
  // --md-sys-typescale-title-large-letter-spacing: 0px;
  // /* title - medium */
  --md-sys-typescale-title-medium-font-family-name: Epilogue;
  // --md-sys-typescale-title-medium-font-family-style: Medium;
  // --md-sys-typescale-title-medium-font-weight: 500px;
  // --md-sys-typescale-title-medium-font-size: 16px;
  // --md-sys-typescale-title-medium-line-height: 24px;
  // --md-sys-typescale-title-medium-letter-spacing: 0.15px;
  // /* title - small */
  --md-sys-typescale-title-small-font-family-name: Epilogue;
  // --md-sys-typescale-title-small-font-family-style: Medium;
  // --md-sys-typescale-title-small-font-weight: 500px;
  // --md-sys-typescale-title-small-font-size: 14px;
  // --md-sys-typescale-title-small-line-height: 20px;
  // --md-sys-typescale-title-small-letter-spacing: 0.10px;
}
